import '../../styles/pages/fall-winter-2020/shifting-gears-to-drive.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2020-stories/more-stories"
import StoryNav from "../../components/fall-winter-2020-stories/story-nav"
import { Parallax, Background } from 'react-parallax';


import TitleGraphic from '../../images/fall-winter-2020/shifting-gears-to-drive/title-graphic.svg';
import Hero from '../../images/fall-winter-2020/shifting-gears-to-drive/hero-image.jpg';
import irwinGillDriving from '../../images/fall-winter-2020/shifting-gears-to-drive/Irwin-Gill-Driving.jpg';

import socialBanner from "../../images/fall-winter-2020/social-banners/shifting-gears-to-drive.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Shifting Gears to Drive';
    var pageDesc = 'A life-changing accident inspired mechanical engineering graduate Irwin Gill ’20 to design a new way to drive.';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="shifting-gears-to-drive" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <section className="feature-hero animated fadeIn slow">
          <div className="hero-text">
            <h1 className="first-word animated fadeInDown delay-1s"><img src={TitleGraphic} alt={pageTitle} /></h1>
            <p className="sub-heading animated fadeInDown delay-2s">After a spinal cord injury, a mechanical engineering grad invents an innovative manual driving system.</p>
            <p className="author animated fadeInUp delay-2s">By Debra Cano Ramos / Photos by Matt Gush</p>
          </div>
        </section>
        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <p><span className="intro-text">Mechanical Engineering</span> graduate Irwin Gill grew up observing his father, a mechanic, repair cars. When he was 8 years old, he watched “The Fast and the Furious” for the first time. The action film about street racing got him hooked on fast, stickshift cars.</p>

          <p>Gill’s passion for all things automotive led him to major in mechanical engineering at Cal State Fullerton and join the Titan Racing Formula SAE (Society of Automotive Engineers) team. The student-led team designs, builds and races a formula-style car for the SAE International competition against other universities in the nation and world.</p>
          </ScrollAnimation>

        </article>

          <ScrollAnimation animateIn="fadeIn">
          <Parallax
          bgImage={Hero}
          bgImageAlt="Alumnus Irwin Gill"
          strength={200}
          className="full-width">
            <div className="caption">
            Alumnus Irwin Gill ’20 (B.S. mechanical engineering) designed a manual driving system for his 2005 BMW 530i sedan
            </div>
            <div className="height" />
          </Parallax>
          </ScrollAnimation>

        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <p>During Thanksgiving break in 2017, Gill had a life-changing motorcycle accident on a dirt racing track near Santa Clarita. He glided over a jump, lost control and slammed straight into a barrier. Gill catapulted 20 feet into the air, plummeted off his motorcycle and landed hard.</p>

          <p>He suffered a spinal cord injury and lost the use of his legs.</p>

          <p>Gill spent two weeks in a Santa Clarita hospital, followed by several more weeks in rehabilitation in Los Angeles. Despite his doctor’s recommendation to take a year off from college, Gill returned to campus for the 2018 spring semester and to his friends on the racing team.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Because of the paralysis in his legs, he couldn’t drive his manual transmission truck, only a car with an automatic transmission. He bought a white 2001 Toyota Camry and installed portable hand controls he purchased for $200 on Amazon. The device allows drivers with disabilities to operate both the brake and gas pedal. It gave Gill his freedom back to drive, including to campus from his Long Beach home.</p>

          <p>But Gill missed the thrill of driving a car with a manual transmission — using a gear stick and clutch to change gears.</p>
          <p>With the knowledge he gained from his classes about engineering design processes, project management and testing, and hands-on experience on the racing team, Gill created an innovative way to drive a stick-shift.</p>

          <p>“As soon as my left leg started to regain function, I figured I could build my own hand control system to drive a manual transmission and use all three pedals — the clutch, brakes and gas.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <p>Using scrap metal from a discarded roll cage, and his teammates’ welding skills and encouragement, he designed, down to the right bolt size, hand controls to fit his 6-foot-2-inch frame.</p>

          <p>Gill installed his manual driving system in a black 2005 BMW 530i sedan with a six-speed manual transmission and 180,000 miles. He’s racked up 30,000 miles over the last two years — and his inventive hand control system has not failed.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={irwinGillDriving} alt="Alumnus Irwin Gill driving" />
            <figcaption>Alumnus Irwin Gill ’20 (B.S. mechanical engineering) designed a manual driving system for his 2005 BMW 530i sedan.</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“My hand controls work by using my left hand to operate both the throttle and brake. My left palm activates the brake, and my left thumb actuates the throttle. My right hand does both the steering and shifting,” explains Gill, a Class of 2020 graduate who is pursuing a career in mechanical engineering.</p>

          <p>The hand control system is permanently mounted in such a position to allow for the top of his left wrist to steer the vehicle and hold the wheel straight, while he takes his right hand off the wheel to shift gears.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>It Takes Teamwork</h2>

          <p>Being on the formula racing team is much more than tackling an engineering design problem from concept to functioning prototype, says Salvador Mayoral, associate professor of mechanical engineering and the team’s faculty adviser.</p>
          <p>Students learn the value of teamwork and become lifelong friends, and alumni return to mentor team members for the next race.</p>
          <p>“Irwin was very engaging in team meetings and always had an optimistic attitude that seemed to resonate with everyone,” Mayoral shares. “I would regularly find him in the shop tinkering with the engine calibration and helping his teammates with their designs.”</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
          <p>This past academic year, Gill was the team’s powertrain engineer, managing the components that generate power to the vehicle, including the engine and transmission. Due to the pandemic, the team did not compete in the Formula SAE California.</p>
          <p>For Gill, applying classroom and racing team know-how to find a real-world solution further fueled his passion for the future.</p>
          <p>“Driving a manual transmission car with hand controls that I built, made me realize that with some ingenuity, I could do almost anything an ablebodied person could do — and that has given me the confidence to go about my life with that mindset.” <span className="end-mark" /></p>
          </ScrollAnimation>
          
          <ScrollAnimation animateIn="fadeIn">
          <p className="learn-more">Support the College of Engineering and Computer Science<br /><a href="https://campaign.fullerton.edu/ecs/" target="_blank">campaign.fullerton.edu/ecs</a></p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="shifting-gears-to-drive" />
        <StoryNav slug="shifting-gears-to-drive" />
      </Layout>
    );
  }
} 